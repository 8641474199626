header{
    padding-top: 20px;
    background-color: #11131b;
    color: #C5C6C7;
}

nav{
    background-color: #11131b;
    color: #C5C6C7;
}

.nav-item{
    margin-left: 10%;
}

.nav-link{
    color: #C5C6C7;
}
