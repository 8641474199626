footer{
    background-color: #1F2833;
    color: #C5C6C7;
    position:fixed;
    bottom: 0;

}

.footer{
    font-weight: bold;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: xx-small;
}

.navbar-nav{
    margin: 0 5px 0 5px;
}
  
