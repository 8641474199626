.card {
  max-height: 500px;
  margin: 1rem;
  width: 45%; 
  padding: 0;
  background: #45A29E;
}

.header{
  font-size: x-large;
  background-color: #11131b;
  padding:  2.5% 2.5% 0 2.5% ;
  color: #C5C6C7;
  text-indent: 5%;
  border-radius: 25px 25px 0 0;
  height: auto;
  height: 50%;
}

.header > p {
  font-size: small;
  font-weight: 100;
}

.card > .img-container {
  width:initial;
  align-self: center;
  overflow: hidden;
  text-align: center;
  padding: 1%;
  height: 300px;
  display: flex;
  align-items: center;
}

.card > .img-container > img {
  border-radius: 15px;
  height: 200px;
  width: 375px;
}

.card > .content {
  font-size: xx-small;
  border: 500px;
  border-color: #C5C6C7;
}

.card > .content > ul {
  list-style-type: none;
}

.card > .content > ul > li {
  margin-top: 18px;
}

.project-nav {
  height: auto;
  border-radius: 0 0 25px 25px;
}