.wrapper {
  height: auto;
  background: #1F2833;
  color: #C5C6C7;
  display: flex;
  flex-flow: row wrap;
  margin: 20px 1% 10px 1%;
  justify-content: space-around;
  align-content: center;
  overflow: auto;
}

.wrapper > .card {
  border-radius: 25px;
}
